export const troubleshootDrawerInitial = {
  title: "",
  isVisible: false,
  caseId: 0,
  workflowType: "",
};

export enum ErrorCategory {
  WORKFLOW = "Workflow",
  ACTIVITY = "Activity",
}

export enum OperationType {
  RE_EXECUTE = "ReExecute",
  IGNORE = "IgnoreExecute",
}

export enum ErrorType {
  ACTIVITY_EXECUTION_FAILED = "ActivityExecutionFailed",
  FAILED_WORKFLOWS = "FailedWorkflows",
  STUCK_WORKFLOWS = "StuckWorkflows",
  NOT_REGISTERED_IN_WORKFLOW = "NotRegisteredInWorkflow",
}

export enum ErrorDescription {
  ACTIVITY_EXECUTION_FAILED = "Activity Execution Failed",
}

export enum TroubleShootSearchType {
  ALL = "All",
  REFERENCE_ID = "ReferenceId",
  CASE_NO = "CaseNo",
}

export enum TroubleshootTableColumns {
  TROUBLESHOOT_BUTTON = "TroubleshootButton",
  REFERENCE_ID = "referenceId",
  CASE_NUMBER = "caseNumber",
  WORKFLOW_STATE = "workflowState",
  REASON = "reason",
  ERROR_CATEGORY = "errorCatagory",
  WORKFLOW_TYPE = "workflowType",
  EXECUTED_DATE = "executedDate",
  DESCRIPTION = "description"
}

export enum ErrorReason {
  ACTIVITY_EXECUTION_FAILED = "Activity Execution Failed",
  FAILED_WORKFLOWS = "Failed Workflows",
  FAILED_ACTIVITY= "Failed Activity",
  STUCK_WORKFLOWS = "Stuck Workflows",
  STUCK_ACTIVITY = "Stuck Activity",
  NOT_REGISTERED_IN_WORKFLOW = "Not registered in Workflow",
}

export enum WorkflowTroubleshootButtons {
  WORKFLOW_RETRY = "WORKFLOW_RETRY",
  ACTIVITY_RETRY = "ACTIVITY_RETRY",
  MOVE_TO = "MOVE_TO",
  IGNORE = "IGNORE",
  DELETE = "DELETE",
}