/**
 * Check first installment date is withing the valid period (part payment creation date + 30 )
 * @param partPaymentCreationDate part payment creation date
 * @param firstInstallmentDate fist installment date
 * @returns return the value of valid or not
 */
export const validateFirstInstallmentDate = (
  partPaymentCreationDate: any,
  firstInstallmentDate: any
) => {
  try {
    const firstInstallmentDateWithTime = new Date(firstInstallmentDate);
    const firstInstallmentDateWithoutTime = new Date(
      firstInstallmentDateWithTime.getFullYear(),
      firstInstallmentDateWithTime.getMonth(),
      firstInstallmentDateWithTime.getDate()
    );
    const maxExpectFirstInstallmentDate = new Date(partPaymentCreationDate);
    maxExpectFirstInstallmentDate.setDate(
      maxExpectFirstInstallmentDate.getDate() + 30
    );
    const isValidFirstInstallmentDate =
    maxExpectFirstInstallmentDate >= firstInstallmentDateWithoutTime;
    return isValidFirstInstallmentDate;
  } catch (error) {
    console.log(error);
    return false;
  }
};
