import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { workflowAction } from "us.collection/actions";
import {
  $Button,
  $DateLabel,
  $Divider,
  $Skeleton,
  $Space,
  $TableTree,
  $Tooltip,
} from "us.common/components";
import { TroubleshootButtons } from "./Components";
import { RootState } from "us.helper/types";
import { ITroubleshootTable } from "us.collection/components/WorkflowTroubleshoot/Interfaces";
import { DoubleLeftOutlined, LeftOutlined, RightOutlined } from "us.icons";
import { getErrorCountByType, filterVisibleColumns, getTroubleshootButtonWidth } from "us.collection/components/WorkflowTroubleshoot/Functions";
import { MonitorList, ReExecuteActivity } from "us.collection/repository";
import { OperationType, TroubleshootTableColumns, WorkflowTroubleshootButtons } from "us.collection/components/WorkflowTroubleshoot"
import { getRouteUrl } from 'us.helper';
import "us.collection/components/WorkflowTroubleshoot/Home.scss";


/**
 * @description - The component used in view of troubleshoot table
 * @link Design Document - https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/3156934695/Troubleshoot+View+-+UI
 * @param {PropsFromRedux} props
 * @return {JSX.Element}
 * @author Tharanga Niroshana <tharangan@unicorn-solutions.com>
 * @since 28/11/2022
 */

const TroubleshootTable: React.FC<PropsFromRedux & ITroubleshootTable> = memo((props) => {
  const { t } = useTranslation();

  const {
    monitorList,
    searchFilters,
    troubleshootCurrentOffset,
    getMonitorList,
    errorCount,
    retryWorkflow,
    retryActivity,
    ignoreActivity,
    deleteActiity,
    openDrawer,
  } = props;

  const handleTroubleshootButtonAction = (
    actionButton: string,
    record: any
  ) => {
    const { caseId, referenceId, workflowType } = record ?? {};
    switch (actionButton) {
      case WorkflowTroubleshootButtons.WORKFLOW_RETRY:
        retryWorkflow &&
          retryWorkflow({
            searchFilters,
            caseId,
          });
        break;
      case WorkflowTroubleshootButtons.ACTIVITY_RETRY:
        retryActivity &&
          retryActivity({
            requestObject: ReExecuteActivity.call(
              record,
              OperationType.RE_EXECUTE
            ),
            searchFilters,
          });

        break;
      case WorkflowTroubleshootButtons.MOVE_TO:
        openDrawer &&
          openDrawer({
            title: "US.COLLECTION.CASE:WORKFLOWS.MOVE_TO",
            isVisible: true,
            caseId,
            workflowType,
          });
        break;
      case WorkflowTroubleshootButtons.IGNORE:
        ignoreActivity &&
          ignoreActivity({
            requestObject: ReExecuteActivity.call(record, OperationType.IGNORE),
            searchFilters,
          });
        break;
      case WorkflowTroubleshootButtons.DELETE:
        deleteActiity &&
          deleteActiity({
            searchFilters,
            pendingexecutionid: referenceId,
          });
        break;
      default:
        break;
    }
  };
  const { searchKey, searchText, workflowErrorType } = searchFilters;

  const columns: any = [
    {
      title: "",
      key: TroubleshootTableColumns.TROUBLESHOOT_BUTTON,
      dataIndex: TroubleshootTableColumns.TROUBLESHOOT_BUTTON,
      width: getTroubleshootButtonWidth(workflowErrorType),
      customRenderChild: (text: any, record: any) => {
        return (
          <TroubleshootButtons
            rowData={record}
            handleTroubleshootButtonAction={handleTroubleshootButtonAction}
          />
        );
      },
    },
    {
      title: t("US.COLLECTION.CASE:WORKFLOWS.REFERENCE_ID"),
      dataIndex: TroubleshootTableColumns.REFERENCE_ID,
      key: TroubleshootTableColumns.REFERENCE_ID,
      width: 130,
      ellipsis: true,
      customSorter: (a: any, b: any) => a - b,
      customFilter: true,
    },
    {
      title: t("US.COLLECTION.CASE:WORKFLOWS.CASE_NO"),
      dataIndex: TroubleshootTableColumns.CASE_NUMBER,
      key: TroubleshootTableColumns.CASE_NUMBER,
      width: 120,
      ellipsis: true,
      customSorter: (a: any, b: any) => a - b,
      customFilter: true,
      customRenderChild: (text: any, record: any) => {
        const { caseNumber } = record ?? {};
        return (
          <a
            onClick={() =>
              getRouteUrl.moduleRoute("collection", `/case/${caseNumber}`)
            }
          >
            {caseNumber}
          </a>
        );
      },
    },
    {
      title: t("US.COLLECTION.CASE:WORKFLOWS.WORKFLOW_STATE"),
      dataIndex: TroubleshootTableColumns.WORKFLOW_STATE,
      key: TroubleshootTableColumns.WORKFLOW_STATE,
      width: 180,
      ellipsis: true,
      customSorter: (a: any, b: any) => a.localeCompare(b),
      customFilter: true,
    },
    {
      title: t("US.COLLECTION.CASE:WORKFLOWS.EXECUTED_TIME"),
      dataIndex: TroubleshootTableColumns.EXECUTED_DATE,
      key: TroubleshootTableColumns.EXECUTED_DATE,
      width: 150,
      customSorter: (a: any, b: any) => a.localeCompare(b),
      customFilter: "date",
      customRenderChild: (text: any, record: any) => {
        return (
          <>
            {record?.executedDate !== null && (
              <$DateLabel value={record?.executedDate} />
            )}
          </>
        );
      },
    },
    {
      title: t("US.COLLECTION.CASE:WORKFLOWS.DESCRIPTION"),
      dataIndex: TroubleshootTableColumns.DESCRIPTION,
      key: TroubleshootTableColumns.DESCRIPTION,
      width: 240,
      ellipsis: true,
      customSorter: (a: any, b: any) => a.localeCompare(b),
      customFilter: true,
    },
    {
      title: t("US.COLLECTION.CASE:WORKFLOWS.REASON"),
      dataIndex: TroubleshootTableColumns.REASON,
      key: TroubleshootTableColumns.REASON,
      width: 180,
      ellipsis: true,
      customSorter: (a: any, b: any) => a.localeCompare(b),
      customFilter: true,
    },
    {
      title: t("US.COLLECTION.CASE:WORKFLOWS.ERROR_CATEGORY"),
      dataIndex: TroubleshootTableColumns.ERROR_CATEGORY,
      key: TroubleshootTableColumns.ERROR_CATEGORY,
      width: 180,
      ellipsis: true,
      customSorter: (a: any, b: any) => a.localeCompare(b),
      customFilter: true,
    },
    {
      title: t("US.COLLECTION.CASE:WORKFLOWS.WORKFLOW_TYPE"),
      dataIndex: TroubleshootTableColumns.WORKFLOW_TYPE,
      key: TroubleshootTableColumns.WORKFLOW_TYPE,
      width: 180,
      ellipsis: true,
      customSorter: (a: any, b: any) => a.localeCompare(b),
      customFilter: true,
    },

  ];

  const handleResetPages = () => {
    getMonitorList &&
      getMonitorList(
        MonitorList.call({
          searchKey,
          searchText,
          offset: 0,
          category: workflowErrorType ?? "",
        })
      );
  };

  const handleNextPage = () => {
    getMonitorList &&
      getMonitorList(
        MonitorList.call({
          searchKey,
          searchText,
          offset: troubleshootCurrentOffset + 100,
          category: workflowErrorType ?? "",
        })
      );
  };

  const handlePreviousPage = () => {
    getMonitorList &&
      getMonitorList(
        MonitorList.call({
          searchKey,
          searchText,
          offset: troubleshootCurrentOffset - 100,
          category: workflowErrorType ?? "",
        })
      );
  };

  return (
    <>
      <div className="d-block">
        <$Skeleton
          loading={monitorList?.isLoading}
          active
          paragraph={{ rows: 2 }}
        >
          <$TableTree
            rowKey={(record: any) => {
              return record.referenceId;
            }}
            onSort={(sortData, dataSource) => {
              return sortData(dataSource);
            }}
            onFilter={(searchData, dataSource) => {
              return searchData(dataSource);
            }}
            filterOnType={true}
            data={monitorList?.data}
            columns={filterVisibleColumns(workflowErrorType,columns)}
            size="small"
            className="mt-3"
            bordered
            scroll={{ x: 900, y: "calc(100vh - 350px)" }}
            defaultExpandAllRows={true}
            resetOnSourceChange={true}
            pagination={false}
          />
        </$Skeleton>
        <div className="d-flex justify-content-end mr-5 mt-3">
          <$Space>
            <$Tooltip title={t("US.COLLECTION.COMMON:COMMON.RESET_PAGES")}>
              <$Button
                type="link"
                icon={<DoubleLeftOutlined />}
                disabled={troubleshootCurrentOffset === 0}
                onClick={() => handleResetPages()}
                data-testid="searchResults-paginationDirectionReset"
              />
            </$Tooltip>
            <$Tooltip title={t("US.COLLECTION.COMMON:COMMON.PREVIOUS_PAGE")}>
              <$Button
                type="link"
                icon={<LeftOutlined />}
                disabled={troubleshootCurrentOffset < 100}
                onClick={() => handlePreviousPage()}
                data-testid="searchResults-paginationDirectionPrev"
              />
            </$Tooltip>
            <$Divider />
            <span>{`${t("US.COLLECTION.COMMON:COMMON.PAGE")} ${
              (troubleshootCurrentOffset + 100) / 100
            }`}</span>
            <$Divider />
            <$Tooltip title={t("US.COLLECTION.COMMON:COMMON.NEXT_PAGE")}>
              <$Button
                type="link"
                icon={<RightOutlined />}
                disabled={
                  getErrorCountByType(errorCount?.data, workflowErrorType) <
                  troubleshootCurrentOffset + 100
                }
                onClick={() => handleNextPage()}
                data-testid="searchResults-paginationDirectionNext"
              />
            </$Tooltip>
          </$Space>
        </div>
      </div>
    </>
  );
});

const { monitoringList , troubleshootWorkflowRetry , troubleshootActivity } = workflowAction;

const mapStateToProps = (state: RootState) => {
  const { Workflow } = state;
  const { monitorList, troubleshootCurrentOffset, errorCount } = Workflow;
  return {
    monitorList,
    troubleshootCurrentOffset,
    errorCount,
  };
};

const mapDispatchToProps = {
  getMonitorList: monitoringList.get,
  retryWorkflow: troubleshootWorkflowRetry.retry,
  retryActivity: troubleshootActivity.retry,
  ignoreActivity: troubleshootActivity.ignore,
  deleteActiity: troubleshootActivity.delete,
  openDrawer: troubleshootWorkflowRetry.openDrawer,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(TroubleshootTable);