import React, { useState, useEffect, useContext } from "react";
import { connect } from "react-redux";
import { Header } from "us.collection.admin/layouts/Bureau/Header";
import { RouteComponentProps, withRouter, matchPath } from "react-router-dom";
import { BoxIcons, IconTypes } from "us.icons";
import { useTranslation } from "react-i18next";
import Common from "us.common";
import { ConfigurationContext } from "us.common/ConfigurationContext/ConfigurationProvider";
import { getSelectedMenuKeyByModule } from "us.collection/functions";
import { $Tooltip } from "us.common/components";

const { $Layout, $Menu } = Common.Components;
const { Content, Sider } = $Layout;

interface ILayout extends RouteComponentProps {}

const Home: React.FC<ILayout> = (props) => {
  const { t } = useTranslation(["US.COLLECTION.LAYOUT", "US.COLLECTION.ADMIN"]);
  const context = useContext(ConfigurationContext);
  const [bureauPermission, setBureauPermission] = useState<any>();
  const { location } = props;
  const BODY_CLASS = "change-body-color-dom";

  const { params }: any = matchPath(props.location.pathname, {
    path: "/bureau/:module?",
    exact: false,
    strict: false,
  });

  useEffect(() => {
    setBureauPermission(context.componentPermission["bureau"]);
  }, [context]);

  useEffect(() => {
    document.body.classList.add(BODY_CLASS);
    return () => {
      document.body.classList.remove(BODY_CLASS);
    };
  }, []);
  const navigate = (url: string): void => {
    window.scrollTo(0, 0);
    props.history.push({ ...location, pathname: `/bureau/${url}` });
  };

  const [isCollapsed, setIsCollapsed] = useState<boolean>(true);
  const onCollapse = (collapsed: boolean): void => {
    setIsCollapsed(collapsed);
  };

  const menuItemAvalibility = (menuItemName: string): boolean => {
    return bureauPermission?.routes[menuItemName]?.isEnabled;
  };

  return (
    <$Layout style={{ minHeight: "100vh" }}>
      <Header {...props}></Header>
      <$Layout>
        <Content className="mt-0">
          <main>{props.children}</main>
        </Content>
        <Sider
          collapsible
          collapsed={isCollapsed}
          onCollapse={(e) => onCollapse(e)}
          reverseArrow={true}
        >
          <$Menu
            theme="dark"
            mode="inline"
            selectedKeys={getSelectedMenuKeyByModule(params["module"])}
          >
            {menuItemAvalibility("routinesAndActivities") && (
              <$Menu.Item
                onClick={() => navigate("history")}
                key="16"
                data-testid="bureau-layout-routinesAndActivities"
              >
                <BoxIcons type={IconTypes.BOX_ICON} name="activity-history" />
                <span>{t("US.COLLECTION.LAYOUT:CASE.HISTORY")}</span>
              </$Menu.Item>
            )}
            {menuItemAvalibility("manageCreditorGroups") && (
              <$Menu.Item
                key="11"
                onClick={() => navigate("manage-creditor-groups")}
                data-testid="bureau-layout-creditorGroups"
              >
                {/* onClick={() => navigate('agreements')} */}
                <BoxIcons type={IconTypes.BOX_ICON} name="creditor-groups" />
                <$Tooltip
                  placement="top"
                  title={t(
                    "US.COLLECTION.LAYOUT:BUREAU.MANAGE_CREDITOR_GROUPS"
                  )}
                >
                  <span>
                    {t("US.COLLECTION.LAYOUT:BUREAU.MANAGE_CREDITOR_GROUPS")}
                  </span>
                </$Tooltip>
              </$Menu.Item>
            )}
            {menuItemAvalibility("bmd") && (
              <$Menu.Item
                key="3"
                onClick={() => navigate("bmd")}
                data-testid="bureau-layout-bmd"
              >
                <BoxIcons type={IconTypes.BOX_ICON} name="bmd" />
                <span>{t("US.COLLECTION.LAYOUT:BUREAU.BMD")}</span>
              </$Menu.Item>
            )}
            {/* <$Menu.Item
              key="4"
              onClick={() => navigate("workflows")}
              data-testid="bureau-layout-bmd"
            >
              <BoxIcons type={IconTypes.BOX_ICON} name="bureau-workflows" />
              <span>{t("US.COLLECTION.LAYOUT:BUREAU.WORKFLOWS")}</span>
            </$Menu.Item>*/}
            <$Menu.Item
              key="5"
              onClick={() => navigate("workflows-troubleshoot")}
            >
              <BoxIcons type={IconTypes.BOX_ICON} name="workflows-troubleshoot" />
              <span>{t("US.COLLECTION.LAYOUT:BUREAU.WORKFLOWS_TROUBLESHOOT")}</span>
            </$Menu.Item> 
          </$Menu>
        </Sider>
      </$Layout>
    </$Layout>
  );
};

const mapStateToProps = (state: any) => {
  return {};
};
const mapDispatchToProps = {};
export default withRouter(
  connect<{}, {}, any>(mapStateToProps, mapDispatchToProps)(Home)
);
