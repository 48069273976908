import React, { memo, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import Common from "us.common";
import { RootState } from "us.helper/types";
import { TroubleshootTableButtons } from "us.collection/components/WorkflowTroubleshoot/Interfaces";
import "us.collection/components/WorkflowTroubleshoot/Home.scss";
import { WorkflowTroubleshootButtons } from "us.collection/components/WorkflowTroubleshoot/Constants";
import { getMatchingWorkflowTroubleshootButtons } from "us.collection/components/WorkflowTroubleshoot/Functions";


const { $Button, $Popconfirm } = Common.Components;

/**
 * @description - The component used in view of troubleshoot table button
 * @link Design Document - https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/3156934695/Troubleshoot+View+-+UI
 * @param {PropsFromRedux} props
 * @return {JSX.Element}
 * @author Tharanga Niroshana <tharangan@unicorn-solutions.com>
 * @since 28/11/2022
 */
const TroubleshootButtons: React.FC<PropsFromRedux & TroubleshootTableButtons> =
  memo((props) => {
    const { t } = useTranslation();

    const { rowData, handleTroubleshootButtonAction } = props;

    const { reason } = rowData ?? {};
    const { WORKFLOW_RETRY, ACTIVITY_RETRY, MOVE_TO, IGNORE, DELETE } =
      WorkflowTroubleshootButtons;

    const troubleshootButtons = useMemo(() => {
      return getMatchingWorkflowTroubleshootButtons(reason);
    }, [reason]);

    return (
      <>
        {troubleshootButtons.includes(WORKFLOW_RETRY) && (
          <$Popconfirm
            title={t("US.COLLECTION.CASE:WORKFLOWS.WORKFLOW_RETRY")}
            placement="topLeft"
            onConfirm={() =>
              handleTroubleshootButtonAction(WORKFLOW_RETRY, rowData)
            }
            okText={t("US.COMMON:COMMON.YES")}
            cancelText={t("US.COMMON:COMMON.NO")}
          >
            <$Button style={{ marginRight: 1 }}>
              {t("US.COLLECTION.CASE:WORKFLOWS.RETRY")}
            </$Button>
          </$Popconfirm>
        )}
        {troubleshootButtons.includes(MOVE_TO) && (
          <$Button
            onClick={() => handleTroubleshootButtonAction(MOVE_TO, rowData)}
            style={{ marginRight: 1 }}
          >
            {t("US.COLLECTION.CASE:WORKFLOWS.MOVE_TO")}
          </$Button>
        )}

        {troubleshootButtons.includes(ACTIVITY_RETRY) && (
          <$Popconfirm
            title={t("US.COLLECTION.CASE:WORKFLOWS.ACTIVITY_RETRY")}
            placement="topLeft"
            onConfirm={() =>
              handleTroubleshootButtonAction(ACTIVITY_RETRY, rowData)
            }
            okText={t("US.COMMON:COMMON.YES")}
            cancelText={t("US.COMMON:COMMON.NO")}
          >
            <$Button style={{ marginRight: 1 }}>
              {t("US.COLLECTION.CASE:WORKFLOWS.RETRY")}
            </$Button>
          </$Popconfirm>
        )}
        {troubleshootButtons.includes(IGNORE) && (
          <$Popconfirm
            title={t("US.COLLECTION.CASE:WORKFLOWS.ACTIVITY_IGNORE")}
            placement="topLeft"
            onConfirm={() => handleTroubleshootButtonAction(IGNORE, rowData)}
            okText={t("US.COMMON:COMMON.YES")}
            cancelText={t("US.COMMON:COMMON.NO")}
          >
            <$Button style={{ marginRight: 1 }}>
              {t("US.COLLECTION.CASE:WORKFLOWS.IGNORE")}
            </$Button>
          </$Popconfirm>
        )}

        {troubleshootButtons.includes(DELETE) && (
          <$Popconfirm
            title={t("US.COLLECTION.CASE:WORKFLOWS.ACTIVITY_DELETE")}
            placement="topLeft"
            onConfirm={() => handleTroubleshootButtonAction(DELETE, rowData)}
            okText={t("US.COMMON:COMMON.YES")}
            cancelText={t("US.COMMON:COMMON.NO")}
          >
            <$Button style={{ marginRight: 1 }}>
              <span className="text-error">{t("US.COMMON:COMMON.DELETE")}</span>
            </$Button>
          </$Popconfirm>
        )}
      </>
    );
  });

const mapStateToProps = (state: RootState) => {
  return {};
};

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(TroubleshootButtons);
